import img from "../../images/services/pulizie-condomini.webp";
import { pageStyles } from "../../styles/style";
import Service from "../../components/Service";
import Footer from "../../components/Footer";
import Downbar from "../../components/down_footer_pulizie";
import Navbar from "../../components/Navbar";
import Banner from "../../components/Banner";
import Works from "../../components/Works";
import { Helmet } from "react-helmet";
import * as React from "react";
import "../../styles/main.css";
import FloatButton from "../../components/FloatButton";


// markup
const IndexPage = () => {
  return (
    <main style={pageStyles}>
      <FloatButton />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Manutenpul</title>
        <meta name="keywords" content="impresa di pulizie milano, impresa di pulizie milano prezzi, impresa pulizie costi milano, impresa di pulizie cesano boscone, impresa di sanificazione, impresa di pulizie lodi" />
      </Helmet>
      <Navbar></Navbar>
      <Downbar></Downbar>
      <Banner pageName="Pulizie Condomini & Residence " prepageName="Servizio" postpageName="Pulizie Condominiali" />
      <Service
        title="Pulizie Condomini a Milano"
        text="Garantire accurate pulizie dei condomini a Milano, è importantissimo perché il 
        condominio è un insieme di famiglie, la cui convivenza è molto difficile.
        Uno dei motivi di discussione tra i condomini è proprio la pulizia. MANUTENPUL 
        srls interviene in tutti i nuclei residenziali per effettuare pulizie ordinarie di 
        scale, disinfezione ascensori, portineria, pianerottoli, casellari postali, vetri, portoni 
        di ingresso, operando con macchinari specifici e prodotti professionali.
        Oltre alle pulizie ordinarie, MANUTENPUL srls è disponibile a fornire servizi supplementari 
        quali sostituzioni custodi, servizi di portineria, portierato, rotazione sacchi spazzatura, 
        sgombero neve e spargimento sale"
        img={img}
      />
      <Works />
      <Footer></Footer>
    </main>
  );
};

export default IndexPage;
